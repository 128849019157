import { isNil } from 'lodash';

import type { components } from '#/types/platformapi-generated';

import type { UnitMetaKey } from '../constants/unit';
import { UnitMeta } from '../constants/unit';

export const DEFAULT_UNIT_PRECISION = 2;

export const getPriceDelta = (
  price1?: string | number,
  price2?: string | number,
  reverseOrder = false,
): number | undefined => {
  let delta: number | undefined;
  if (!isNil(price1) && !isNil(price2)) {
    const mainNum = parseNumber(price1);
    const referenceNum = parseNumber(price2);
    if (isNaN(mainNum) || isNaN(referenceNum)) {
      delta = undefined;
    }

    if (reverseOrder) {
      // Use precision to avoid EPSILON difference
      delta = Number((referenceNum - mainNum).toPrecision(15));
    } else {
      delta = Number((mainNum - referenceNum).toPrecision(15));
    }
  }

  return delta;
};

export const getFormattedPrice = (
  val?: string | number | null,
  {
    unit,
    withUnit,
    placeholder = '-',
    precision = DEFAULT_UNIT_PRECISION,
  }: {
    unit?:
      | components['schemas']['AssessmentUnitEnum']
      | 'usd'
      | 'eur'
      | 'pence-per-therm'
      | 'usdPerDay'
      | 'usdPerMMBtu'
      | 'eurPerMwh';
    withUnit?: boolean;
    placeholder?: string;
    precision?: number;
  } = {},
): string => {
  let value = placeholder;
  const numberVal = isNil(val) ? NaN : parseNumber(val);
  if (isNaN(numberVal)) {
    value = placeholder;
  } else {
    switch (unit) {
      case 'usdPerDay':
      case 'usd-per-day':
        value = formatNaturalNumber(numberVal);
        break;
      case 'usdPerMMBtu':
      case 'usd-per-mmbtu':
        value =
          numberVal >= 0
            ? numberVal.toFixed(precision)
            : `(${(numberVal * -1).toFixed(precision)})`;

        break;
      case 'eurPerMwh':
      case 'eur-per-mwh':
        value =
          numberVal >= 0
            ? numberVal.toFixed(precision)
            : `(${(numberVal * -1).toFixed(precision)})`;

        break;
      case 'eur':
      case 'usd':
        value = formatNaturalNumber(numberVal);
        break;
      default:
        value = formatNaturalNumber(numberVal);
        break;
    }
  }

  if (unit && withUnit) {
    value = getPriceWithUnit(value, unit);
  }
  return value;
};

export const getPriceWithUnit = (price: string, unit: UnitMetaKey): string => {
  let value = price;
  const meta = UnitMeta[unit];
  if (meta) {
    value = `${meta.prefix}${price}${meta.separator}${meta.suffix}`;
  }
  return value;
};

export const parseNumber = (
  val: string | number | null | undefined,
): number => {
  if (isNil(val)) {
    return NaN;
  } else {
    return typeof val === 'number' ? val : parseFloat(val);
  }
};

export const getPercentagePrice = (
  price: string | number | undefined | null,
  ratio: number,
) => {
  if (!isNil(price) && !isNaN(+price)) {
    return (Math.round(+price * ratio * 100) / 100)?.toString();
  } else {
    return undefined;
  }
};

export const formatNaturalNumber = (val: number | string) => {
  const formattedVal = val
    .toString()
    .replace(/\.(\d)*$/, '')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    .replace('-', '');
  return +val >= 0 ? formattedVal : `(${formattedVal})`;
};

export const formatNumber = (val: number, precision: number) => {
  const isFractional = val % 1 !== 0;
  let value: number | string = val;
  if (isFractional) {
    value = val.toFixed(precision);
  }

  return +value >= 0
    ? value.toLocaleString('en-US')
    : `(${(-1 * +value).toLocaleString('en-US')})`;
};
