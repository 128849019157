import {
  ACCESS_SPARKR_CHANGELOG,
  ACCESS_SPARKRF_COSTS,
  BASIS_DISCOVER_FEATURES,
  BASIS_FFA_SPARKNWE_FEATURES,
  BASIS_FFA_SPARKSWE_FEATURES,
  BASIS_NETBACK,
  BASIS_PHYSICAL_SPARKNWE_FEATURES,
  BASIS_PHYSICAL_SPARKSWE_FEATURES,
  BASIS_PRICE_EVOLUTION,
  features,
  FREIGHT_ALERT_CENTRE,
  FREIGHT_DASHBOARD_FEATURES,
  FREIGHT_DISCOVER_FEATURES,
  FREIGHT_FFA_DISCOVER_FEATURES,
  FREIGHT_FFA_PRICE_EVOLUTION,
  FREIGHT_FFA_SPARK25_FEATURES,
  FREIGHT_FFA_SPARK30_FEATURES,
  FREIGHT_FFA_SPREAD_FEATURES,
  FREIGHT_FFA_SUBMISSION,
  FREIGHT_GLOBAL_CALCULATOR,
  FREIGHT_NOTIF_PREF_FEATURES,
  FREIGHT_OFFSET_CALCULATOR,
  FREIGHT_OPTI_FEATURES,
  FREIGHT_PHYSICAL_SPARK25_FEATURES,
  FREIGHT_PHYSICAL_SPARK30_FEATURES,
  FREIGHT_PRICE_ALERTS_FEATURES,
  FREIGHT_PRICE_CALCULATOR,
  FREIGHT_PRICE_EVOLUTION_FEATURES,
  FREIGHT_ROUTES_DISCOVER_FEATURES,
  FREIGHT_SPREAD_FEATURES,
  INTEGRATIONS,
  SPARK_SIGNALS,
  SPARK_WEBHOOKS,
} from '@/shared/misc/constants';
import type { FeatureVersion } from '@/types';

export const featureVersions = {
  [features.FREIGHT_DASHBOARD]: {
    [FREIGHT_DASHBOARD_FEATURES.DISCOVERY]: -1,
    [FREIGHT_DASHBOARD_FEATURES.SIGNALS]: -1,
    [FREIGHT_DASHBOARD_FEATURES.SETTLEMENT_TRACKER]: -1,
    [FREIGHT_DASHBOARD_FEATURES.SPREAD_CHARTING]: -1,
  },
  [features.FREIGHT_DISCOVER]: {
    [FREIGHT_DISCOVER_FEATURES.ALL]: -1,
    [FREIGHT_DISCOVER_FEATURES.COMPARE_CHARTS]: 1,
  },
  [features.FREIGHT_PHYSICAL_SPARK30]: {
    [FREIGHT_PHYSICAL_SPARK30_FEATURES.ALL]: -1,
  },
  [features.FREIGHT_PHYSICAL_SPARK25]: {
    [FREIGHT_PHYSICAL_SPARK25_FEATURES.ALL]: -1,
  },
  [features.FREIGHT_FFA_SPARK30]: {
    [FREIGHT_FFA_SPARK30_FEATURES.ALL]: -1,
  },
  [features.FREIGHT_FFA_SPARK25]: {
    [FREIGHT_FFA_SPARK25_FEATURES.ALL]: -1,
  },
  [features.FREIGHT_SETTLEMENT_TRACKER]: {
    [FREIGHT_DISCOVER_FEATURES.ALL]: 1,
  },
  [features.FREIGHT_SPREAD]: {
    [FREIGHT_SPREAD_FEATURES.ALL]: 1,
  },
  [features.FREIGHT_FFA_SPREAD]: {
    [FREIGHT_FFA_SPREAD_FEATURES.ALL]: 1,
  },
  [features.FREIGHT_PRICE_CALCULATOR]: {
    [FREIGHT_PRICE_CALCULATOR.ALL]: 1,
  },
  [features.FREIGHT_PRICE_EVOLUTION]: {
    [FREIGHT_PRICE_EVOLUTION_FEATURES.ALL]: 1,
    [FREIGHT_PRICE_EVOLUTION_FEATURES.DELTA_BOXES]: -1,
    [FREIGHT_PRICE_EVOLUTION_FEATURES.PRICE_EVOLUTION_CHART]: -1,
    [FREIGHT_PRICE_EVOLUTION_FEATURES.PRICE_EVOLUTION_TABLE]: -1,
    [FREIGHT_PRICE_EVOLUTION_FEATURES.PRICE_DELTA_CHART]: -1,
  },
  [features.FREIGHT_FFA_PRICE_EVOLUTION]: {
    [FREIGHT_FFA_PRICE_EVOLUTION.ALL]: 1,
    [FREIGHT_FFA_PRICE_EVOLUTION.PRICE_EVOLUTION_CHART]: -1,
    [FREIGHT_FFA_PRICE_EVOLUTION.PRICE_EVOLUTION_TABLE]: -1,
    [FREIGHT_FFA_PRICE_EVOLUTION.PRICE_DELTA_CHART]: -1,
  },
  [features.FREIGHT_OPTI]: {
    [FREIGHT_OPTI_FEATURES.ALL]: -1,
  },
  [features.FREIGHT_PRICE_ALERTS]: {
    [FREIGHT_PRICE_ALERTS_FEATURES.ALL]: -1,
  },
  [features.NOTIFICATION_PREFERENCES]: {
    [FREIGHT_NOTIF_PREF_FEATURES.ALL]: -1,
    [FREIGHT_NOTIF_PREF_FEATURES.WHATSAPP_NUMBER]: -1,
    [FREIGHT_NOTIF_PREF_FEATURES.LNG_FREIGHT]: -1,
    [FREIGHT_NOTIF_PREF_FEATURES.LNG_BASIS]: -1,
  },
  [features.FREIGHT_OFFSET_CALCULATOR]: {
    [FREIGHT_OFFSET_CALCULATOR.ALL]: -1,
    [FREIGHT_OFFSET_CALCULATOR.CALCULATOR]: -1,
    [FREIGHT_OFFSET_CALCULATOR.TREE_PROGRESS]: -1,
    [FREIGHT_OFFSET_CALCULATOR.ACTIVITY_FEED]: -1,
  },
  [features.FREIGHT_FFA_SUBMISSION]: {
    [FREIGHT_FFA_SUBMISSION.ALL]: -1,
  },
  [features.FREIGHT_FFA_DISCOVER]: {
    [FREIGHT_FFA_DISCOVER_FEATURES.ALL]: -1,
    [FREIGHT_FFA_DISCOVER_FEATURES.COMPARE_CHARTS]: 1,
  },
  [features.FREIGHT_ROUTES_DISCOVER]: {
    [FREIGHT_ROUTES_DISCOVER_FEATURES.ALL]: 1,
    [FREIGHT_ROUTES_DISCOVER_FEATURES.DOWNLOAD]: 1,
    [FREIGHT_ROUTES_DISCOVER_FEATURES.DOWNLOAD_XLS]: 2,
  },
  [features.FREIGHT_GLOBAL_CALCULATOR]: {
    [FREIGHT_GLOBAL_CALCULATOR.ALL]: 2,
  },
  [features.BASIS_DISCOVER]: {
    [BASIS_DISCOVER_FEATURES.ALL]: -1,
    [BASIS_DISCOVER_FEATURES.COMPARE_CHARTS]: 1,
  },
  [features.BASIS_PHYSICAL_SPARKNWE]: {
    [BASIS_PHYSICAL_SPARKNWE_FEATURES.ALL]: -1,
  },
  [features.BASIS_PHYSICAL_SPARKSWE]: {
    [BASIS_PHYSICAL_SPARKSWE_FEATURES.ALL]: -1,
  },
  [features.BASIS_FFA_SPARKNWE]: {
    [BASIS_FFA_SPARKNWE_FEATURES.ALL]: -1,
  },
  [features.BASIS_FFA_SPARKSWE]: {
    [BASIS_FFA_SPARKSWE_FEATURES.ALL]: -1,
  },
  [features.BASIS_PRICE_EVOLUTION]: {
    [BASIS_PRICE_EVOLUTION.ALL]: -1,
  },
  [features.BASIS_NETBACK]: {
    [BASIS_NETBACK.ALL]: -1,
  },
  [features.FREIGHT_ALERT_CENTRE]: {
    [FREIGHT_ALERT_CENTRE.ALL]: -1,
    [FREIGHT_ALERT_CENTRE.WHATSAPP]: -1,
    [FREIGHT_ALERT_CENTRE.NOTIFICATION_PREFERENCES]: -1,
    [FREIGHT_ALERT_CENTRE.USER_DEFINED_ALERTS]: -1,
  },
  [features.INTEGRATIONS]: {
    [INTEGRATIONS.ALL]: 1,
    [INTEGRATIONS.EXCEL]: -1,
    [INTEGRATIONS.API]: -1,
    [INTEGRATIONS.WEBHOOKS]: -1,
    [INTEGRATIONS.ENVERUS]: -1,
    [INTEGRATIONS.MORNINGSTAR]: -1,
    [INTEGRATIONS.ZEMA_GLOBAL]: -1,
  },
  [features.SPARK_WEBHOOKS]: {
    [SPARK_WEBHOOKS.ALL]: -1,
    [SPARK_WEBHOOKS.INTRODUCTION]: -1,
  },

  [features.ACCESS_SPARKRF_COSTS]: {
    [ACCESS_SPARKRF_COSTS.ALL]: -1,
    [ACCESS_SPARKRF_COSTS.HUB_SLIPPAGE]: 1,
  },
  [features.ACCESS_SPARKR_CHANGELOG]: {
    [ACCESS_SPARKR_CHANGELOG.ALL]: -1,
  },
  [features.SPARK_SIGNALS]: {
    [SPARK_SIGNALS.ALL]: -1,
  },
} as FeatureVersion;

export default featureVersions;
