import { isNil } from 'lodash';
import { defineStore, storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';

import { lngCrossProductFeatures } from '@/core/components/the-nav/mainMenuConfig';
import { config } from '@/core/components/the-nav/subMenuConfig';
import { useAuthStore } from '@/core/stores/auth';
import { useChangelogStore } from '@/products/access/stores/changelog';
import { features, permissions } from '@/shared/misc/constants';
import type { components } from '@/types';
import type { Nullable } from '#/types/core';
import { replacePlaceholders } from '#/utils/string';

import { CONTRACTS, TOOLS } from '../components/the-nav/constants';
import { useFeatureVersionStore } from './featureVersion';

export type MenuItem = {
  title: string;
  to?: string;
  icon: string;
  subTitle?: string;
  isLocked?: boolean;
  showRedDot: boolean;
  uiTourId?: string;
  items?: {
    title: string;
    to: string;
    showRedDot: boolean;
  }[];
};

export type CallToActionContext = {
  renderAs: 'a' | 'router-link';
  href: string;
  description: string;
  openCrispChatIfAvailable?: boolean;
  preFillCrispChatMessage?: string;
};

const useMenuStore = defineStore('menu', () => {
  const featureVersionStore = useFeatureVersionStore();
  const authStore = useAuthStore();
  const route = useRoute();

  const { shouldShowRedDot, dismissFeature } = featureVersionStore;
  const { freightPlan, cargoPlan, accessPlan, lebaPlan, intradayPlan } =
    storeToRefs(authStore);
  const { isFeatureLocked } = authStore;

  // FREIGHT

  const freightContracts = computed(() => {
    const contracts =
      config.freight
        .find((f) => f.title === CONTRACTS)
        ?.items.map<MenuItem>((contractCategory) => {
          return {
            title: contractCategory.title,
            to: contractCategory.to,
            icon: contractCategory.icon,
            subTitle: contractCategory.subTitle,
            isLocked: isFeatureLocked(
              contractCategory.permission,
              'lng-freight-platform',
              contractCategory.compareUsing,
            ),
            showRedDot: contractCategory.feature
              ? shouldShowRedDot(contractCategory.feature, route?.name)
              : false,
            uiTourId: contractCategory.uiTourId,
            items: contractCategory.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? [];

    if (freightPlan.value === 'basic-plan') {
      const basicDashboard = {
        title: 'Dashboard',
        icon: 'dashboard',
        isLocked: false,
        showRedDot: false,
        items: [
          {
            title: 'Spark30',
            to: '/freight/basic-dashboard/spark30',
            feature: features.FREIGHT_BASIC_DASHBOARD,
            showRedDot: shouldShowRedDot(
              features.FREIGHT_BASIC_DASHBOARD,
              route?.name,
            ),
          },
          {
            title: 'Spark25',
            to: '/freight/basic-dashboard/spark25',
            feature: features.FREIGHT_BASIC_DASHBOARD,
            showRedDot: shouldShowRedDot(
              features.FREIGHT_BASIC_DASHBOARD,
              route?.name,
            ),
          },
        ],
      };
      contracts.splice(0, 0, basicDashboard);
    }

    return contracts;
  });

  const freightTools = computed(() => {
    const tools =
      config.freight
        .find((f) => f.title === TOOLS)
        ?.items.map<MenuItem>((tool) => {
          return {
            title: tool.title,
            to: tool.to,
            icon: tool.icon,
            subTitle: tool.subTitle,
            isLocked: isFeatureLocked(
              tool.permission,
              'lng-freight-platform',
              tool.compareUsing,
            ),
            showRedDot: tool.feature
              ? shouldShowRedDot(tool.feature, route?.name)
              : false,
            items: tool.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? [];

    return tools;
  });

  const freightMenu = computed(() => [
    {
      title: CONTRACTS,
      items: freightContracts.value,
    },
    {
      title: TOOLS,
      items: freightTools.value,
    },
  ]);

  const freightCallToActions = computed(() => {
    const actions: CallToActionContext[] = [];

    if (freightPlan.value === 'basic-plan') {
      actions.push({
        renderAs: 'router-link',
        href: '/freight-intro',
        description: 'Upgrade to enjoy all features',
      });
    }

    if (freightPlan.value === 'trial-plan') {
      actions.push({
        renderAs: 'a',
        href: 'mailto:info@sparkcommodities.com',
        description: getUpgradeText('lng-freight-platform'),
        openCrispChatIfAvailable: true,
        preFillCrispChatMessage: 'I would like to upgrade my trial plan',
      });
    }

    return actions;
  });

  // LEBA

  const lebaContracts = computed(() => {
    const contracts =
      config.leba
        .find((f) => f.title === CONTRACTS)
        ?.items.map<MenuItem>((contractCategory) => {
          return {
            title: contractCategory.title,
            to: contractCategory.to,
            icon: contractCategory.icon,
            subTitle: contractCategory.subTitle,
            isLocked: isFeatureLocked(
              contractCategory.permission,
              'gas-leba-platform',
              contractCategory.compareUsing,
            ),
            showRedDot: contractCategory.feature
              ? shouldShowRedDot(contractCategory.feature, route?.name)
              : false,
            uiTourId: contractCategory.uiTourId,
            items: contractCategory.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? [];

    if (lebaPlan.value === 'basic-plan') {
      const basicDashboard = {
        title: 'Dashboard',
        icon: 'dashboard',
        isLocked: false,
        showRedDot: false,
        items: [
          {
            title: 'Spark30',
            to: '/freight/basic-dashboard/spark30',
            feature: features.FREIGHT_BASIC_DASHBOARD,
            showRedDot: shouldShowRedDot(
              features.FREIGHT_BASIC_DASHBOARD,
              route?.name,
            ),
          },
          {
            title: 'Spark25',
            to: '/freight/basic-dashboard/spark25',
            feature: features.FREIGHT_BASIC_DASHBOARD,
            showRedDot: shouldShowRedDot(
              features.FREIGHT_BASIC_DASHBOARD,
              route?.name,
            ),
          },
        ],
      };
      contracts.splice(0, 0, basicDashboard);
    }

    return contracts;
  });

  const lebaTools = computed(() => {
    const tools =
      config.leba
        .find((f) => f.title === TOOLS)
        ?.items.map<MenuItem>((tool) => {
          return {
            title: tool.title,
            to: tool.to,
            icon: tool.icon,
            subTitle: tool.subTitle,
            isLocked: isFeatureLocked(
              tool.permission,
              'gas-leba-platform',
              tool.compareUsing,
            ),
            showRedDot: tool.feature
              ? shouldShowRedDot(tool.feature, route?.name)
              : false,
            items: tool.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? [];

    return tools;
  });

  const lebaMenu = computed(() => [
    {
      title: CONTRACTS,
      items: lebaContracts.value,
    },
    {
      title: TOOLS,
      items: lebaTools.value,
    },
  ]);

  const lebaCallToActions = computed(() => {
    const actions: CallToActionContext[] = [];

    if (lebaPlan.value === 'basic-plan') {
      actions.push({
        renderAs: 'router-link',
        href: '/freight-intro',
        description: 'Upgrade to enjoy all features',
      });
    }

    if (lebaPlan.value === 'trial-plan') {
      actions.push({
        renderAs: 'a',
        href: 'mailto:info@sparkcommodities.com',
        description: getUpgradeText('lng-freight-platform'),
        openCrispChatIfAvailable: true,
        preFillCrispChatMessage: 'I would like to upgrade my trial plan',
      });
    }

    return actions;
  });

  // CARGO

  const cargoContracts = computed(() => {
    const contracts =
      config.cargo
        .find((f) => f.title === CONTRACTS)
        ?.items.map<MenuItem>((contractCategory) => {
          return {
            title: contractCategory.title,
            to: contractCategory.to,
            icon: contractCategory.icon,
            subTitle: contractCategory.subTitle,
            isLocked: isFeatureLocked(
              contractCategory.permission,
              'lng-basis-platform',
              contractCategory.compareUsing,
            ),
            showRedDot: contractCategory.feature
              ? shouldShowRedDot(contractCategory.feature, route?.name)
              : false,
            uiTourId: contractCategory.uiTourId,
            items: contractCategory.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? [];

    if (
      !isFeatureLocked(
        permissions.ACCESS_SPARK_CLOSE_SANDBOX,
        'lng-basis-platform',
        'includes',
      )
    ) {
      contracts.push({
        title: 'SparkClose',
        to: '/cargo/sandbox/spark-close/',
        icon: 'pricediscovery',
        subTitle: 'SANDBOX',
        showRedDot: shouldShowRedDot(
          features.BASIS_SPARK_CLOSE_SANDBOX,
          route?.name,
        ),
      });
    } else {
      contracts.push({
        title: 'SparkClose',
        to: '/cargo/spark-close-sandbox-intro/',
        icon: 'pricediscovery',
        subTitle: 'SANDBOX',
        showRedDot: shouldShowRedDot(
          features.BASIS_SPARK_CLOSE_SANDBOX_INTRO,
          route?.name,
        ),
      });
    }

    return contracts;
  });

  const cargoTools = computed(() => {
    return (
      config.cargo
        .find((f) => f.title === TOOLS)
        ?.items.map<MenuItem>((tool) => {
          return {
            title: tool.title,
            to: tool.to,
            icon: tool.icon,
            subTitle: tool.subTitle,
            isLocked: isFeatureLocked(
              tool.permission,
              'lng-basis-platform',
              tool.compareUsing,
            ),
            showRedDot: tool.feature
              ? shouldShowRedDot(tool.feature, route?.name)
              : false,
            items: tool.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? []
    );
  });

  const cargoMenu = computed(() => [
    {
      title: CONTRACTS,
      items: cargoContracts.value,
    },
    {
      title: TOOLS,
      items: cargoTools.value,
    },
  ]);

  const cargoCallToActions = computed(() => {
    const actions: CallToActionContext[] = [];

    if (cargoPlan.value === 'basic-plan') {
      actions.push({
        renderAs: 'router-link',
        href: '/cargo-intro',
        description: 'Upgrade to enjoy all features',
      });
    }

    if (cargoPlan.value === 'trial-plan') {
      actions.push({
        renderAs: 'a',
        href: 'mailto:info@sparkcommodities.com',
        description: getUpgradeText('lng-basis-platform'),
        openCrispChatIfAvailable: true,
        preFillCrispChatMessage: 'I would like to upgrade my trial plan',
      });
    }

    return actions;
  });

  // ACCESS

  const accessTools = computed(() => {
    return (
      config.access
        .find((f) => f.title === TOOLS)
        ?.items.map<MenuItem>((tool) => {
          return {
            title: tool.title,
            to: tool.to,
            icon: tool.icon,
            subTitle: tool.subTitle,
            isLocked: isFeatureLocked(
              tool.permission,
              'access-platform',
              tool.compareUsing,
            ),
            showRedDot: tool.feature
              ? shouldShowRedDot(tool.feature, route?.name) ||
                (tool.feature === features.ACCESS_SPARKR_CHANGELOG &&
                  useChangelogStore().hasUpdate())
              : false,
            items: tool.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? []
    );
  });

  const accessMenu = computed(() => [
    {
      title: TOOLS,
      items: accessTools.value,
    },
  ]);

  const accessCallToActions = computed(() => {
    const actions: CallToActionContext[] = [];

    if (accessPlan.value === 'trial-plan') {
      actions.push({
        renderAs: 'a',
        href: 'mailto:info@sparkcommodities.com',
        description: getUpgradeText('access-platform'),
        openCrispChatIfAvailable: true,
        preFillCrispChatMessage: 'I would like to upgrade my trial plan',
      });
    }

    return actions;
  });

  const intradayMenu = computed(() => []);

  const intradayCallToActions = computed(() => {
    const actions: CallToActionContext[] = [];

    if (intradayPlan.value === 'trial-plan') {
      actions.push({
        renderAs: 'a',
        href: 'mailto:info@sparkcommodities.com',
        description: getUpgradeText('intraday-platform'),
        openCrispChatIfAvailable: true,
        preFillCrispChatMessage: 'I would like to upgrade my trial plan',
      });
    }

    return actions;
  });

  // CROSS PRODUCT FEATURES

  const crossProductFeaturesMenu = computed(() => ({
    lng: lngCrossProductFeatures
      .filter((item) => {
        if (!item.customShowLogic) {
          return true;
        }
        return item.customShowLogic(authStore);
      })
      .map((item) => {
        if (!item.customOverride) {
          return item;
        }
        return item.customOverride(authStore, item);
      }),
    gas: [],
  }));

  const activeCommodity = computed(() => {
    return route.meta.product === 'gas-leba-platform' ? 'gas' : 'lng';
  });

  const isSubmenuExpanded = computed(() => {
    return subMenuConfig.value.sections.length > 0;
  });

  // Note: There are three master configs - main menu, side menu, top menu
  const mainMenuConfig = computed(() => {
    return {
      borderRightColor: (() => {
        switch (route.meta.product) {
          case 'lng-freight-platform':
            return 'border-green-500';
          case 'lng-basis-platform':
            return 'border-basis-500';
          case 'access-platform':
            return 'border-access-500';
          case 'gas-leba-platform':
            return 'border-gas-500';
          default:
            return 'border-gray-700';
        }
      })(),
      items: {
        lng: [
          {
            to: '/freight',
            title: 'Freight',
            icon: 'freight',
            subscriptionPlan: freightPlan.value,
            showRedDot: determineMainMenuRedDot(
              freightContracts.value,
              freightTools.value,
              freightPlan.value,
            ),
          } as const,
          {
            to: '/cargo',
            title: 'Cargo',
            icon: 'basis',
            subscriptionPlan: cargoPlan.value,
            showRedDot: determineMainMenuRedDot(
              cargoContracts.value,
              cargoTools.value,
              cargoPlan.value,
            ),
          } as const,
          {
            to: '/access',
            title: 'Access',
            icon: 'access',
            subscriptionPlan: accessPlan.value,
            showRedDot: determineMainMenuRedDot(
              [],
              accessTools.value,
              accessPlan.value,
            ),
          } as const,
          {
            to: '/intraday',
            title: 'Intraday',
            icon: 'chartCandlestick',
            subscriptionPlan: intradayPlan.value,
            showRedDot: determineMainMenuRedDot([], [], intradayPlan.value),
          } as const,
        ],
        gas: [
          {
            to: '/gas/leba',
            title: 'LEBA',
            icon: 'basis',
            subscriptionPlan: lebaPlan.value,
            showRedDot: determineMainMenuRedDot(
              lebaContracts.value,
              lebaTools.value,
              lebaPlan.value,
            ),
          } as const,
        ],
      },
    };
  });

  const subMenuConfig = computed(() => {
    const product = route.meta.product;

    switch (product) {
      case 'lng-freight-platform':
        return {
          title: 'Freight',
          titleColor: 'text-green-500',
          sections: freightMenu.value,
          callToActions: freightCallToActions.value,
          subscriptionPlan: freightPlan.value,
        };

      case 'lng-basis-platform':
        return {
          title: 'Cargo',
          titleColor: 'text-basis-400',
          sections: cargoMenu.value,
          callToActions: cargoCallToActions.value,
          subscriptionPlan: cargoPlan.value,
        };

      case 'access-platform':
        return {
          title: 'Access',
          titleColor: 'text-access-500',
          sections: accessMenu.value,
          callToActions: accessCallToActions.value,
          subscriptionPlan: accessPlan.value,
        };

      case 'gas-leba-platform':
        return {
          title: 'Gas',
          titleColor: 'text-gas-500',
          sections: lebaMenu.value,
          callToActions: lebaCallToActions.value,
          subscriptionPlan: lebaPlan.value,
        };

      default:
        return {
          title: '',
          titleColor: '',
          sections: [],
          callToActions: [],
          subscriptionPlan: undefined,
        };
    }
  });

  const topMenuConfig = computed<{
    items: {
      hide?: boolean;
      productButton: {
        to: string;
        label: string;
        icon: string;
        color: 'green' | 'basis' | 'access' | 'gas' | 'white';
        iconSize?: string;
      };
      subMenu: {
        menu: {
          title: string;
          items: MenuItem[];
        }[];
        oneColumn?: boolean;
        callToActions: CallToActionContext[];
      };
    }[];
  }>(() => {
    return {
      items: [
        {
          productButton: {
            to: '/freight',
            label: 'Freight',
            icon: 'freight',
            color: 'green',
          },
          subMenu: {
            menu: freightMenu.value,
            callToActions: freightCallToActions.value,
          },
        },
        {
          productButton: {
            to: '/cargo',
            label: 'Cargo',
            icon: 'basis',
            color: 'basis',
          },
          subMenu: {
            menu: cargoMenu.value,
            callToActions: cargoCallToActions.value,
          },
        },
        {
          productButton: {
            to: '/access',
            label: 'Access',
            icon: 'access',
            color: 'access',
            iconSize: '20',
          },
          subMenu: {
            menu: accessMenu.value,
            callToActions: accessCallToActions.value,
            oneColumn: true,
          },
        },
        {
          hide:
            lebaPlan.value !== 'premium-plan' &&
            lebaPlan.value !== 'trial-plan',
          productButton: {
            to: '/gas',
            label: 'LEBA',
            icon: 'basis',
            color: 'gas',
            iconSize: '20',
          },
          subMenu: {
            menu: lebaMenu.value,
            callToActions: lebaCallToActions.value,
          },
        },
        {
          productButton: {
            to: '/intraday',
            label: 'Intraday',
            icon: 'chartCandlestick',
            color: 'white',
            iconSize: '20',
          },
          subMenu: {
            menu: intradayMenu.value,
            callToActions: intradayCallToActions.value,
          },
        },
      ] as const,
    };
  });

  const showCommoditySwitch = computed(() => {
    const lebaPlan = authStore.lebaPlan;
    return lebaPlan === 'premium-plan' || lebaPlan === 'trial-plan';
  });

  function getTrialDaysLeft(product: components['schemas']['ProductEnum']) {
    const subscription = authStore.availableSubscriptions.find(
      (s) => s.product === product,
    );
    let daysLeft: Nullable<number> = null;
    if (subscription && subscription.plan === 'trial-plan') {
      daysLeft = authStore.getTrialDaysLeft(subscription);
    }
    return daysLeft;
  }

  function getUpgradeText(product: components['schemas']['ProductEnum']) {
    const daysLeft = getTrialDaysLeft(product);
    if (daysLeft !== null && daysLeft !== undefined && daysLeft > 0) {
      const daysOrDay = daysLeft > 1 ? `days` : `day`;
      return `${daysLeft} ${daysOrDay} left. Upgrade Now!`;
    }
    return 'Upgrade Now!';
  }

  function handleDismissFeature() {
    // handle feature id with params
    if (route.meta.featureIdTemplate && route.params) {
      const keyValues: Record<string, string> = {};

      for (const key in route.params) {
        const value = route.params[key];
        if (typeof value === 'string') {
          keyValues[key] = value;
        }
      }

      const featureId = replacePlaceholders(
        route.meta.featureIdTemplate,
        keyValues,
      );

      dismissFeature({ featureId });
    }
    // else dismiss feature by route name (default behavior)
    else if (typeof route.name === 'string') {
      dismissFeature({ featureId: route.name });
    }
  }

  function determineMainMenuRedDot(
    contracts: MenuItem[],
    tools: MenuItem[],
    plan?: string,
  ) {
    const hasSubscription = !isNil(plan) && plan !== 'no-plan';

    const hasRedDotChildren =
      contracts.some((item) => item.showRedDot) ||
      contracts.some((item) =>
        item.items?.some((subItem) => subItem.showRedDot),
      ) ||
      tools.some((item) => item.showRedDot);

    return hasSubscription && hasRedDotChildren;
  }

  watch(
    () => route.path,
    () => {
      handleDismissFeature();
    },
    { immediate: true },
  );

  return {
    freightTools,
    cargoTools,
    accessTools,
    lebaTools,
    crossProductFeaturesMenu,
    activeCommodity,
    isSubmenuExpanded,
    showCommoditySwitch,
    // Master menu configs
    mainMenuConfig,
    subMenuConfig,
    topMenuConfig,
  };
});

export { useMenuStore };
