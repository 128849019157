import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import type { Pinia } from 'pinia';
import { defineStore, getActivePinia } from 'pinia';
import { computed, ref, watch } from 'vue';

import router from '@/core/router';
import { authService, userAccountService } from '@/core/services';
import { useAppStore } from '@/core/stores/app';
import { analyticsTrack } from '@/core/utils/usageAnalytics';
import { showErrorNotification } from '@/shared/misc/notification';
import {
  type AllVerticalPriceReleaseAlerts,
  AnalyticsEvent,
  type components,
  type PatchAccountPayload,
  type UIMetadata,
} from '@/types';

import { LocalStorageManager } from '../utils/localstorage';
import { useMarketingStore } from './marketing';
import { useWebSocketStore } from './websocket';

const useAuthStore = defineStore('auth', () => {
  // state

  // Function to resolve loading promise
  let loadingResolveFn: () => void;

  // Unresolved on init. To be resolved only when user is logged in
  const loadingPromise = ref<Promise<void>>(
    new Promise((resolve) => {
      loadingResolveFn = resolve;
    }),
  );
  const oAuthClients = ref<
    (
      | components['schemas']['OAuthClient']
      | components['schemas']['ClearTextOAuthClient']
    )[]
  >([]);
  const loggedIn = ref<boolean>(false);
  const error = ref<string | null>('');
  const accessToken = ref<string>();
  const userAccount = ref<components['schemas']['AccountResponse'] | undefined>(
    undefined,
  );
  const EXCLUDE_FROM_RESET = ['auth'];
  const isSavingUiMetadata = ref<boolean>(false);

  // getters
  const displayName = computed(() => {
    if (userAccount.value) {
      return `${userAccount.value.firstName || ''} ${
        userAccount.value.lastName || ''
      }`;
    }
    return '';
  });

  const hasSubmissionRole = computed(() => {
    const productConfig = activeProductConfig.value;
    return !!(
      productConfig &&
      productConfig.canAccess &&
      productConfig.permissions?.find((v: string) =>
        v.startsWith('submit-prices'),
      )
    );
  });

  const hasSubmissionRoleFFA = computed(() => {
    const productConfig = activeProductConfig.value;
    return !!(
      productConfig &&
      productConfig.canAccess &&
      productConfig.permissions?.find((v: string) =>
        v.startsWith('submit-ffa-prices'),
      )
    );
  });

  const getTrialDaysLeft = computed(() => {
    return (config?: components['schemas']['Subscription']) => {
      const productConfig = config || activeProductConfig.value;
      if (productConfig?.plan === 'trial-plan' && productConfig?.endAt) {
        const expiryDate = dayjs(productConfig.endAt).startOf('day');
        const expiresIn = expiryDate.diff(dayjs().startOf('day'), 'days');
        return expiresIn;
      }
      return null;
    };
  });

  const trialOver = computed(() => {
    return activeProductConfig.value?.didATrial;
  });

  const hasPermission = computed(() => {
    return (
      permissionType: string,
      product?: components['schemas']['ProductEnum'],
    ) => {
      const productConfig = product
        ? userAccount.value?.subscriptions?.find((v) => v.product === product)
        : activeProductConfig.value;

      return productConfig?.permissions.includes(permissionType);
    };
  });

  const hasPermissionsAmong = computed(() => {
    return (
      permissionType: string,
      product?: components['schemas']['ProductEnum'],
    ) => {
      const productConfig = product
        ? userAccount.value?.subscriptions?.find((v) => v.product === product)
        : activeProductConfig.value;

      const permissions = productConfig?.permissions;
      return !!permissions?.find((v: string) => v.startsWith(permissionType));
    };
  });

  const activeProductConfig = computed(() =>
    userAccount.value?.subscriptions?.find(
      (v) => v.product === useAppStore()?.activeProductId,
    ),
  );

  const activeVertical = computed(() => activeProductConfig.value?.vertical);

  const planType = computed(() => {
    return activeProductConfig.value?.plan;
  });

  const inTrial = computed(() => {
    return planType.value === 'trial-plan';
  });

  const isPremiumPlan = computed(() => {
    return planType.value === 'premium-plan';
  });

  const isBasicPlan = computed(() => {
    return planType.value === 'basic-plan';
  });

  const apiSubscriptions = computed(() => {
    return userAccount.value?.subscriptions?.filter(
      (v) => v.isActive && v.canAccess && v?.productType === 'commercial-api',
    );
  });

  const availableSubscriptions = computed(() => {
    const userSubscriptions = userAccount.value?.subscriptions?.filter(
      (v) => v.isActive && v.canAccess && v?.productType === 'platform',
    );
    return (
      userSubscriptions?.sort((p1, p2) => {
        if (p1.product < p2.product) {
          return 1;
        } else if (p1.product > p2.product) {
          return -1;
        }
        return 0;
      }) || []
    );
  });

  const priceReleaseAlertsForAllProducts = computed(() => {
    const getTopicPreference = (
      productConfig: components['schemas']['Subscription'],
    ) => {
      const vertical = productConfig?.vertical;
      return (
        userAccount.value?.notificationPreferences
          ?.filter(
            (notificationPreference) =>
              vertical === notificationPreference.vertical,
          )
          .map((preference) => {
            return { ...preference, key: preference.topic };
          }) || []
      );
    };

    return availableSubscriptions.value?.reduce(
      (
        accumulator: AllVerticalPriceReleaseAlerts,
        subscription: components['schemas']['Subscription'],
      ) => {
        accumulator[subscription.product] = getTopicPreference(subscription);
        return accumulator;
      },
      {},
    );
  });

  const priceReleaseAlertsForActiveProduct = computed<
    components['schemas']['NotificationPreference'][]
  >(() => {
    const appStore = useAppStore();
    if (appStore?.activeProductId) {
      return (
        priceReleaseAlertsForAllProducts.value?.[appStore.activeProductId] || []
      );
    }
    return [];
  });

  const isSparkUser = computed(() => {
    return !!userAccount.value?.email?.includes('@sparkcommodities.com');
  });

  const freightPlan = computed(() => {
    return availableSubscriptions.value.find(
      (s) => s.product === 'lng-freight-platform',
    )?.plan;
  });

  const cargoPlan = computed(() => {
    return availableSubscriptions.value.find(
      (s) => s.product === 'lng-basis-platform',
    )?.plan;
  });
  const accessPlan = computed(() => {
    return availableSubscriptions.value.find(
      (s) => s.product === 'access-platform',
    )?.plan;
  });

  const intradayPlan = computed(() => {
    return availableSubscriptions.value.find(
      (s) => s.product === 'intraday-platform',
    )?.plan;
  });

  const lebaPlan = computed(() => {
    return availableSubscriptions.value.find(
      (s) => s.product === 'gas-leba-platform',
    )?.plan;
  });

  const hubPlan = computed(() => {
    return availableSubscriptions.value.find(
      (s) => s.product === 'hub-platform',
    )?.plan;
  });

  const uiMetadata = computed(() => {
    // use type assertion because schema is not typed properly [key: string]: never
    return (userAccount.value?.uiMetadata as Partial<UIMetadata>) ?? {};
  });

  // actions
  function setOAuthClients(clients: components['schemas']['OAuthClient'][]) {
    const sortedClients = clients?.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      } else if (a.createdAt > b.createdAt) {
        return -1;
      } else {
        return 0;
      }
    });
    oAuthClients.value = sortedClients || [];
  }

  function updateOAuthClient(
    client: components['schemas']['ClearTextOAuthClient'],
  ) {
    const index = oAuthClients.value.findIndex(
      (v) => v.clientId === client.clientId,
    );
    if (index > -1) {
      oAuthClients.value[index] = client;
    }
  }

  function setHasVisitedMandatoryOnboardingPageState(bool: boolean) {
    if (userAccount.value) {
      userAccount.value.hasVisitedOnboardingPage = bool;
    }
  }

  async function loadAuthenticatedUser(): Promise<boolean> {
    const appStore = useAppStore();
    appStore.loadingApp = true;

    try {
      await refreshToken();
      return true;
    } catch (error) {
      resetSession();
      console.error('loadAuthenticatedUser failed', error);
    } finally {
      loadingResolveFn();
    }
    return false;
  }

  async function logout(options?: { skipTriggerEvent?: boolean }) {
    if (!loggedIn.value) {
      return;
    }
    try {
      analyticsTrack(AnalyticsEvent.Logout);
      await authService.logout();
      await useWebSocketStore().close(1000, 'Logout');
      resetSession();

      if (!options?.skipTriggerEvent) {
        LocalStorageManager.triggerLogoutEvent();
      }
      loggedIn.value = false;
      router.push('/login');
    } catch (err) {
      console.warn('logout api failure', err);
    }
  }

  function resetSession() {
    userAccount.value = undefined;
    accessToken.value = undefined;
    const stores = getActivePinia()?.state.value;
    if (stores) {
      Object.keys(stores)
        .filter((store) => !EXCLUDE_FROM_RESET.includes(store))
        .forEach((store) => {
          (getActivePinia() as Pinia & { _s: any })?._s?.get(store)?.$dispose();
        });
    }
  }

  async function refreshToken(): Promise<void> {
    const response = await authService.doRefreshToken();
    const appStore = useAppStore();
    appStore.loadingApp = true;
    try {
      if (response.success) {
        const account = response.data?.account;
        if (account) {
          userAccount.value = account;
          loggedIn.value = true;
          accessToken.value = response.data?.access_token;
        } else {
          await logout();
        }
      } else {
        await logout();
      }
    } finally {
      appStore.loadingApp = false;
    }
  }

  async function loginWithPass({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    if (loggedIn.value) {
      return;
    }
    error.value = null;
    try {
      // logout first to clear state
      resetSession();

      analyticsTrack(AnalyticsEvent.LoginWithPassword);
      const response = await authService.login(email, password);
      if (response.success) {
        const account = response.data?.account;
        if (account) {
          userAccount.value = account;
          loggedIn.value = true;
          accessToken.value = response.data?.access_token;
          loadingResolveFn();
          analyticsTrack(AnalyticsEvent.LoginSuccess);
          // schedule passive refresh token
          // dispatch('refreshTokensBeforeExpiry', response.data?.expires_in);
        } else {
          throw new Error(
            'Login failure. Please try again later or contact us.',
          );
        }
      } else {
        throw response.error;
      }
    } catch (err: any) {
      resetSession();
      const errorMsg =
        typeof err === 'string' ? err : err?.response?.data?.error?.message;
      error.value = errorMsg;
      analyticsTrack(AnalyticsEvent.LoginError, {
        error: err,
      });
      throw errorMsg;
    }
  }

  async function refreshTokensBeforeExpiry(expiresIn = 120) {
    const expiresInMs = +expiresIn * 1000;
    const TWO_MINUTES_IN_MS = 2 * 60 * 1000; // do it 2 minutes before expiry
    setTimeout(() => {
      refreshToken();
    }, expiresInMs - TWO_MINUTES_IN_MS);
  }

  async function synchronizeUserAccountInfo() {
    try {
      const { data: account } = await userAccountService.getMyAccountInfo();
      if (account) {
        userAccount.value = account;
      }
    } catch (error) {
      console.warn('Account synchronisation failed', error);
    }
  }

  function registerUser(user: components['schemas']['CreateAccountPayload']) {
    return userAccountService.registerUser(user);
  }

  function setHasVisitedMandatoryOnboarding() {
    // Update locally just in case remote call to change flag fails
    // So they can navigate away from free-trial page temporarily
    // Later if user refreshes app manually, free-trial page will show again
    setHasVisitedMandatoryOnboardingPageState(true);
    return userAccountService.updateUserAccount({
      hasVisitedOnboardingPage: true,
    });
  }

  async function updateUserAccount(userProfile: PatchAccountPayload) {
    try {
      const response = await userAccountService.updateUserAccount(userProfile);
      if (response?.data?.data) {
        userAccount.value = response.data.data;
        return { success: true, data: response?.data };
      }
    } catch (e: any) {
      return { success: false, error: e?.response?.data?.error };
    }
  }

  function deleteUserAccount(pwd: string) {
    return userAccountService.deleteUserAccount(pwd);
  }

  function verifyWhatsapp(code: string) {
    return userAccountService.verifyWhatsappNumber(code).then(() => {
      userAccount.value = Object.assign(userAccount.value || {}, {
        whatsappNumberVerifiedAt: dayjs().format('DD MMM YYYY'),
      }) as components['schemas']['AccountResponse'];
    });
  }

  async function getOAuthClients() {
    const clients = await userAccountService.getOAuthClients();
    oAuthClients.value = clients;
  }

  async function deleteOAuthClient(clientId: string) {
    try {
      const response = await userAccountService.deleteOAuthClient(clientId);
      if (response.status === 200) {
        const clients = oAuthClients.value?.filter(
          (v) => v.clientId !== clientId,
        );
        oAuthClients.value = clients;
        return response;
      } else {
        error.value = 'An error has occurred. Please try again later';
      }
    } catch {
      error.value = 'An error has occurred. Please try again later';
    }
  }

  async function addOAuthClient(params: {
    name: string;
    clientType: components['schemas']['OauthClientTypeEnum'];
  }) {
    const client = await userAccountService.addOAuthClient(params).catch(() => {
      error.value = 'An error has occurred. Please try again later';
    });
    if (!error.value) {
      const newClient = {
        ...client,
        name: params.name,
      };
      const clients = [newClient, ...oAuthClients.value];
      oAuthClients.value = clients;
      return newClient;
    }
  }

  async function resetOAuthClientSecret(clientId: string) {
    const client = await userAccountService
      .resetOAuthClientSecret(clientId)
      .catch(() => {
        error.value = 'An error has occurred. Please try again later';
      });
    if (client && !error.value) {
      updateOAuthClient(client);
    }
  }

  function isFeatureLocked(
    permission: string,
    product: components['schemas']['ProductEnum'],
    compareUsing: 'startsWith' | 'includes',
  ) {
    const permissions = availableSubscriptions.value.find(
      (s) => s.product === product,
    )?.permissions;

    const found =
      compareUsing === 'startsWith'
        ? !!permissions?.find((p: string) => p.startsWith(permission))
        : !!permissions?.includes(permission);
    return !found;
  }

  function getUiMetadataValue<K extends keyof Partial<UIMetadata>>(
    key: K,
    defaultValue?: UIMetadata[K],
  ) {
    return (
      (userAccount.value?.uiMetadata as unknown as UIMetadata)?.[key] ??
      defaultValue
    );
  }

  async function getAccessToken() {
    // no need to refresh token if it's not expired
    if (accessToken.value) {
      const decoded = jwtDecode(accessToken.value);
      if (decoded.exp) {
        const isExpired = decoded.exp * 1000 < Date.now();
        if (!isExpired) {
          return accessToken.value;
        }
      }
    }

    await refreshToken();
    return accessToken.value ?? '';
  }

  async function saveUiMetadataValue<K extends keyof Partial<UIMetadata>>(
    key: K,
    value: UIMetadata[K],
  ) {
    // Consider happy flow for non critical UI only updates to have a quicker response
    if (userAccount.value?.uiMetadata) {
      (userAccount.value.uiMetadata as unknown as UIMetadata) = {
        ...userAccount.value.uiMetadata,
        [key]: value,
      } as unknown as UIMetadata;
    }

    try {
      isSavingUiMetadata.value = true;
      await updateUserAccount({
        uiMetadata: {
          ...userAccount.value?.uiMetadata,
          [key]: value,
        },
      });
    } catch (error) {
      showErrorNotification('Failed to save User preferences', error);
    } finally {
      isSavingUiMetadata.value = false;
    }
  }

  watch(loggedIn, (loggedInValue) => {
    if (loggedInValue) {
      useMarketingStore().loadFeedbacks({ forceRefresh: true });
    }
  });

  return {
    // state
    loadingPromise,
    oAuthClients,
    loggedIn,
    error,
    accessToken,
    userAccount,
    // getters
    displayName,
    hasSubmissionRole,
    hasSubmissionRoleFFA,
    getTrialDaysLeft,
    trialOver,
    hasPermission,
    hasPermissionsAmong,
    apiSubscriptions,
    activeProductConfig,
    activeVertical,
    planType,
    inTrial,
    isPremiumPlan,
    isBasicPlan,
    availableSubscriptions,
    priceReleaseAlertsForAllProducts,
    priceReleaseAlertsForActiveProduct,
    isSparkUser,
    freightPlan,
    lebaPlan,
    cargoPlan,
    accessPlan,
    intradayPlan,
    hubPlan,
    uiMetadata,
    isSavingUiMetadata,
    // actions
    setOAuthClients,
    updateOAuthClient,
    setHasVisitedMandatoryOnboarding,
    loadAuthenticatedUser,
    logout,
    resetSession,
    getAccessToken,
    refreshToken,
    loginWithPass,
    refreshTokensBeforeExpiry,
    synchronizeUserAccountInfo,
    registerUser,
    updateUserAccount,
    deleteUserAccount,
    verifyWhatsapp,
    getOAuthClients,
    deleteOAuthClient,
    addOAuthClient,
    resetOAuthClientSecret,
    isFeatureLocked,
    getUiMetadataValue,
    saveUiMetadataValue,
  };
});

export { useAuthStore };
