import type { useAuthStore } from '@/core/stores/auth';
import type { components } from '@/types';
import type { Nullable } from '#/types/core';

export type RedirectLogicCallback = (
  subscriptions: components['schemas']['Subscription'][],
) => Nullable<string>;

export type MainMenuItem = {
  to: string;
  icon: string;
  title: string;
  customShowLogic?: (authStore: ReturnType<typeof useAuthStore>) => boolean;
  customOverride?: (
    authStore: ReturnType<typeof useAuthStore>,
    item: MainMenuItem,
  ) => MainMenuItem;
};

export const commodityConfig = [
  {
    to: '/freight',
    key: 'lng',
    title: 'LNG',
  } as const,
  {
    to: '/gas',
    key: 'gas',
    title: 'Gas',
  } as const,
];

export const lngCrossProductFeatures: MainMenuItem[] = [
  {
    to: '/spark-signals',
    icon: 'signals',
    title: 'Signals',
  },
  {
    to: '/spark-hub',
    icon: 'globe',
    title: 'Hub',
    // TODO: remove this logic when the feature is ready to show every user,
    // for now only shown to users with hub-platform subscription for testing
    customShowLogic: (authStore) => {
      return (
        authStore.userAccount?.subscriptions.some(
          (s) => s.product === 'hub-platform',
        ) ?? false
      );
    },
    // set link to /spark-hubs-intro for users on trial-plan or basic-plan
    customOverride: (authStore, mainMenuItem) => {
      const plan = authStore.hubPlan;
      if (plan === 'trial-plan' || plan === 'basic-plan') {
        return {
          ...mainMenuItem,
          to: '/spark-hubs-intro',
        };
      }
      return mainMenuItem;
    },
  },
];

export const miscFeatures: MainMenuItem[] = [
  {
    to: '/data-integrations',
    icon: 'plug',
    title: 'Data Integrations',
  },
  {
    to: '/more',
    icon: 'more',
    title: 'More',
  },
];

export const accountFeatures: MainMenuItem[] = [
  {
    to: '/account/profile',
    icon: 'profile',
    title: 'Profile',
  },
  {
    to: '/logout',
    icon: 'logOut',
    title: 'Logout',
  },
];
